import axios from 'axios';

axios.interceptors.response.use(null, error => {
    const expecedError = error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expecedError) {
        console.log("An unexpected error has occurred")
    } else if (error.response.status === 403) {
        console.log("You do not have access to this function");
    }

    return Promise.reject(error);
});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    all: axios.all
}