import http from './HttpService';

export function makePayment(data) {
    return http.post(`api/payments`, data);
}

export function searchReceipt(number, fair) {
    return http.get(`api/payments/receipt?number=${number}&fair=${fair}`);
}

export function sendEmail(data) {
    return http.post(`api/payments/email`, data);
}