import React, { Component } from 'react';
import { withRouter } from "react-router";
import BreadCrumbs from '../components/Breadcrumbs';
import Results from '../components/Results';
import withSiteTemplate from '../HOC/site';
import { searchOrganisation } from '../services/OrganisationService';
import { storeItem, getItem } from './../services/StorageService';

const breadcrumbs = [
    { link: "/", name: "Home" }
]


class SearchResults extends Component {

    constructor(props) {
        super(props);

        const search = getItem('search');

        if (!search)
            window.location = "/";

        const query = JSON.parse(search);

        this.state = {
            breadcrumbs: breadcrumbs,
            searchTerm: query.searchTerm,
            searchBy: query.searchBy,
            isSearchingResults: false,
            showWarning: false,
            data : null
        }
    }

    async componentDidMount() {
        const { searchTerm } = this.state;
        await this.searchOrganisation(null, searchTerm);
    }

    searchOrganisation = async (e, term) => {
        
        if (term === null) {
            const { history } = this.props;
            history.push("/");
            return;
        }

        if (term.length === 0)
            return;
        
        window.scrollTo(0, 0);
        this.setState({ isSearchingResults: true, breadcrumbs, searchTerm: term });
        const response = await searchOrganisation(term);

        const showWarning = response.status !== 200 ? true : false;
        this.setState({ showWarning, isSearchingResults: false, data: response.data });
    }

    handleItemSelect = (key, keyName, nsInternalId, pNumber) => {
        const { history } = this.props;
        storeItem('form', JSON.stringify({
            customerNo: key,
            customerName: keyName,
            customerPNumber: pNumber,
            customerNsInternalId: nsInternalId,
            customerFairNo: 0,
            customerFairNsInternalId: ""
        }));
        history.push('/form');
    }

    render() {
        const { breadcrumbs, isSearchingResults, searchTerm, data, showWarning } = this.state;
        return (
            <React.Fragment>
                <BreadCrumbs
                    title={"Search Results"}
                    links={breadcrumbs} />
                <Results
                    onItemSelect={this.handleItemSelect}
                    data={data}
                    showWarning={showWarning}
                    isLoading={isSearchingResults}
                    searchTerm={searchTerm}
                    onSearch={this.searchOrganisation}/>
            </React.Fragment>
        );
    }
}

export default withSiteTemplate(withRouter(SearchResults));