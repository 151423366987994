import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer'

const withSiteTemplate = (Component) => {

    return (
        <React.Fragment>
            <Navigation />
            <Component />
            <Footer />
        </React.Fragment>
    )
}

export default withSiteTemplate;