import React, { Component } from 'react';
import { withRouter } from "react-router";
import BreadCrumbs from '../components/Breadcrumbs';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import withSiteTemplate from '../HOC/site';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { CreditCardFill, TelephoneFill } from 'react-bootstrap-icons';

const breadcrumbs = [
    { link: "/", name: "Home" }
]

class Error extends Component {

    constructor(props) {
        super(props);

        let params = new URLSearchParams(props.location.search);

        var data = {}

        for (const [key, value] of params.entries())
            data[key] = value;

        this.state = {
            data
        };
    }

    componentDidMount() {

        const { data } = this.state;

        if (!data)
            return window.location.href = '/';

        if (!data.message || !data.receipt)
            return window.location.href = '/';

    }

    goToContactUs = (e) => {
        return window.location.href = "https://scholastic.com.au/about-scholastic/contact-us/"
    }

    makePayment = (e) => {
        e.preventDefault();
        const { history } = this.props;
        const previousUrl = `/form`;
        history.push(previousUrl);
    }

    render() {
        const { data } = this.state;
        return (
            <React.Fragment>
                <BreadCrumbs
                    className="no-print"
                    title={"Error Payment"}
                    links={breadcrumbs} />
                    <Container id="notes">
                        <Row>
                            <Col><h4>We had a problem with your payment</h4></Col>
                    </Row>
                    <Row id="error-msg">
                        <Col>
                            <Alert variant={"danger"}>
                                {data !== undefined || data.message !== null ? data.message : "There was an error when trying to make a payment. Please record the receipt number below for your reference."}
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p id="error-receipt">Transaction reference number:</p>
                            <p id="error-receipt-value">{data !== undefined || data.receipt !== null ? data.receipt : "-" }</p>
                        </Col>
                    </Row>
                    <Row id="error-buttons">
                        <Col>
                            <Button variant="purple" size="md" style={{ marginRight: "1rem" }} onClick={this.makePayment} ><CreditCardFill /> Make Payment Again</Button>
                            <Button variant="outline-info" size="md" onClick={(e) => this.goToContactUs(e)}><TelephoneFill />  Contact Us</Button>
                        </Col>
                    </Row>
                    </Container>
            </React.Fragment>
        );
    }
}

export default withSiteTemplate(withRouter(Error));