import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Search } from 'react-bootstrap-icons';
import { storeItem, getItem } from './../services/StorageService';


export default class SearchBar extends Component {

    state = {
        searchTerm: this.props.term === undefined ? "" : this.props.term,
        showToolTip: false
    }

    handleOnChangeSearchTerm = (e) => {
        let showToolTip = e.target.value.length > 0 ? false : true;
        this.setState({ showToolTip, searchTerm: e.target.value });
    }

    handleOnKeyUp = (e) => {

        if (e.charCode === 13) {
            this.handleOnSearch(e);
        }
    }

    handleOnSearch = (e) => {

        const { searchTerm } = this.state;

        if (searchTerm === undefined || searchTerm.length === 0) {
            this.setState({ showToolTip: true })
            return;
        }

        const search = getItem('search');
        const query = JSON.parse(search);

        query.searchTerm = searchTerm;
        storeItem('search', JSON.stringify(query));

        const { onSearch } = this.props;
        onSearch(e, searchTerm);
    }

    renderPopOver = (text) => {
        return (
            <Popover id="popover-basic">
                <Popover.Content>
                    {text}
                </Popover.Content>
            </Popover>)
    }


    render() {
        const { searchTerm, showToolTip } = this.state;

        return (
            <React.Fragment>
                <InputGroup size="lg" className="shadow">
                    <OverlayTrigger trigger={null} rootClose={true} rootCloseEvent="click" show={showToolTip} placement="bottom" overlay={this.renderPopOver(<p id="search-bar-tooltip-text">Please type your <strong>school</strong>, <strong>suburb</strong> or <strong>postcode</strong>.</p> )}>
                        <FormControl
                            placeholder="Search by school, suburb or postcode..."
                            aria-label="School"
                            aria-describedby="basic-addon2"
                            value={searchTerm}
                            onChange={this.handleOnChangeSearchTerm}
                            onKeyPress={this.handleOnKeyUp}
                        />
                    </OverlayTrigger>
                    <InputGroup.Append>
                        <Button variant="purple" onClick={(e) => this.handleOnSearch(e)}><Search id="search-button" size={15} /></Button>
                    </InputGroup.Append>
                </InputGroup>
            </React.Fragment>
        );
    }
}
