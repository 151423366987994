import React, { Component } from 'react';
import { withRouter } from "react-router";
import BreadCrumbs from '../components/Breadcrumbs';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FormControl from 'react-bootstrap/FormControl';
import SimpleTable from '../components/SimpleTable';
import withSiteTemplate from '../HOC/site';
import { ReceiptCutoff, PrinterFill, EnvelopeFill } from 'react-bootstrap-icons';
import { searchReceipt, sendEmail } from '../services/PaymentService';

const breadcrumbs = [
    { link: "/", name: "Home" }
]

class Receipt extends Component {



    constructor(props) {
        super(props);

        let params = new URLSearchParams(props.location.search);
        var data = {}

        for (const [key, value] of params.entries())
            data[key] = value;

        this.state = {
            data,
            receipt: null,
            showEmail: false,
            sendingEmail: false,
            emailAddress: "",
            sendEmailResponse: 0
        }
    }

    async componentDidMount() {
        const { data } = this.state;
        const response = await searchReceipt(data.Receipt, data.Fair);
        
        if (response.data === "")
            window.location.href = "/";

        this.setState({ receipt: response.data });
    }

    onEmailChange = (e) => {
        this.setState({ emailAddress: e.target.value });
    }

    goHome = () => {
        const { history } = this.props;
        history.push("/");
    }

    print = () => {
        window.print();
    }

    toggleEmail = () => {
        const { showEmail } = this.state;

        this.setState({ showEmail : !showEmail });
    }

    sendEmail = async () => {
        const { receipt, emailAddress } = this.state;

        this.setState({ sendingEmail: true });

        receipt['emailAddress'] = emailAddress;
        const response = await sendEmail(receipt);

        const sendEmailResponse = response.data ? 1 : 2;

        this.setState({ sendingEmail: false, sendEmailResponse });   
    }

    renderEmailMessage = () => {
        const { sendEmailResponse } = this.state;

        if (sendEmailResponse === 0) return null;
        
        return (
            <p className={sendEmailResponse === 1 ? "success" : "error"} id="email-message">{ sendEmailResponse === 1 ? "Receipt has been sent" : "There was an error sending your receipt. Please make sure the email address is valid." }</p>
        )
    }

    renderEmailDetails = () => {
        const { sendingEmail } = this.state;

        return (
            <div id="email-container" className="no-print">
                <p>Please enter a valid email address:</p>
                <InputGroup className="mb-3" onChange={this.onEmailChange}>
                    <FormControl
                        placeholder="Email address"
                        aria-label="Email address"
                        aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                        <Button variant="primary" disabled={sendingEmail ? true : false} onClick={this.sendEmail} >{sendingEmail ? <Spinner size={"sm"} animation="border" /> : "Send"} </Button>
                    </InputGroup.Append>
                </InputGroup>
                { this.renderEmailMessage() }
            </div>
        )
    }

    renderTools = () => {
        const { showEmail } = this.state;

        return (
            <div className="no-print">
                <Button  variant="primary" id="btn-email" onClick={this.toggleEmail}><EnvelopeFill /> Email</Button>
                <Button variant="primary" id="btn-print" onClick={this.print}><PrinterFill /> Print</Button>
                <Button variant="purple" onClick={this.goHome}>New Transaction</Button>
                {showEmail ? this.renderEmailDetails() : null}
            </div>
            )
    }

    render() {
        
        const { data, receipt } = this.state;
        
        if (data === null || receipt === null)
            return null;

        
        delete data["emailAddress"];
        delete receipt["emailAddress"];


        return (
            <React.Fragment>
                <BreadCrumbs
                    className="no-print"
                    title={"Payment Complete"}
                    links={breadcrumbs} />
                <SimpleTable
                    header={<h3><ReceiptCutoff className="header-3-icon" /> Please record your details below:</h3>}
                    data={receipt}
                    more={this.renderTools()} />
                
            </React.Fragment>
        );
    }
}

export default withSiteTemplate(withRouter(Receipt));