export function storeItem(key, value) {
    localStorage[key] = value;
}

export function removeItem(key) {
    localStorage.removeItem(key);
}

export function getItem(key) {
    return localStorage[key];
}