import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class NotFound extends Component {

    renderImage = (image) => {
        if (image === undefined || image === null)
            return null;

        return <img src={image} alt="Scholastic Book Fairs" />
    }

    render() {
        const { title, description, more, image } = this.props;
        return (
            <Row>
                <Col xs={12} md={6} lg={6}>
                    <h1 id="page-not-found-title">{title}</h1>
                    <h4 id="page-not-found-description">{description}</h4>
                    <div id="page-not-found-more-container">{more}</div>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    {this.renderImage(image)}
                </Col>
            </Row>
        );
    }
}
