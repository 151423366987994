import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { CircleFill } from 'react-bootstrap-icons';

export default class SimpleTable extends Component {

    renderTableData = () => {
        const { data } = this.props;

        if (data === null || data === undefined)
            return null;

        var results = [];

        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                const value = data[property];
                results.push({ name: this.capitalizeFirstLetter(property), value });
            }
        }

        return results.map(r => (
            <tr key={r.name}>
                <td><CircleFill size={10} className="primary-color list-leading-indicator indicator-trailing-space" />{r.name}</td>
                <td>{r.value}</td>
            </tr>
        ))
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    renderMore = () => {
        const { more } = this.props;

        if (more === null || more === undefined)
            return null;

        return (
            <Row>
                <Col>
                    <h5 id="notes-footer" className="primary-color">{more}</h5>
                </Col>
            </Row>
        )
    }

    render() {
        const { header } = this.props;
        return (
            <Container id="notes">
                <Row>
                    <Col>{header}</Col>
                </Row>
                <Row>
                    <Col>
                        <Table id="table-simple" borderless striped hover className="shadow">
                            <tbody>
                                {this.renderTableData()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 id="notes-footer" className="primary-color">Thank you for supporting Scholastic Book Fairs!</h5>
                    </Col>
                </Row>
                {this.renderMore()}
            </Container>
        );
    }
}