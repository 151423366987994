import React, { Component } from 'react';
import { withRouter } from "react-router";
import BreadCrumbs from '../components/Breadcrumbs';
import CompletionForm from '../components/CompletionForm';
import withSiteTemplate from '../HOC/site';
import { searchFairsByPNumber } from '../services/OrganisationService';
import { getItem, storeItem } from './../services/StorageService';

const breadcrumbs = [
    { link: "/", name: "Home" },
    { link: "/search", name: "Search" }
]

class Form extends Component {

    constructor(props) {
        super(props);

        breadcrumbs[1].link = `/search`;

        this.state = {
            data: null,
            breadcrumbs: breadcrumbs,
            selectedItem: null,
            isLoadingData: true
        }
    }

    async componentDidMount() {

        const { history } = this.props;

        const form = getItem('form');

        if (!form) {
            history.push("/");
            return
        }

        const formValues = JSON.parse(form);
        await this.searchFairs(formValues.customerPNumber);
    }

    searchFairs = async (pNumber) => {
        this.setState({ isLoadingData: true });
        const response = await searchFairsByPNumber(pNumber);

        var data = null;
        var selectedItem = null;

        if (response.status === 200) {
            data = response.data;

            selectedItem = data != null && data.length === 1 ? data[0] : null;
        }

        if (selectedItem) {
            const form = getItem('form');
            const formValues = JSON.parse(form);

            formValues.customerFairNo = selectedItem.number;
            formValues.customerFairNsInternalId = selectedItem.nsInternalID;

            storeItem('form', JSON.stringify(formValues));
        }


        this.setState({ isLoadingData: false, selectedItem, data });
    }

    handleSelectedItem = (item) => {
        
        const form = getItem('form');
        const formValues = JSON.parse(form);

        formValues.customerFairNo = item.number;
        formValues.customerFairNsInternalId = item.nsInternalID;

        storeItem('form', JSON.stringify(formValues));

        this.setState({ selectedItem: item });
    }

    handleOnStepChange = async () => {
        const { data } = this.state;

        if (data.length === 1)
            return;

        const form = getItem('form');
        const formValues = JSON.parse(form);

        await this.searchFairs(formValues.customerPNumber);
    }

    render() {
        window.scrollTo(0, 0);

        const { history } = this.props;
        const { breadcrumbs, isLoadingData, selectedItem, data } = this.state;

        var step = 0;

        if (data == null || data === undefined || data.length === 0)
            step = 0;
        else
            if (selectedItem !== null)
                step = 2
            else 
                step = data.length === 1 ? 2 : 1;
        
        return (
            <React.Fragment>
                <BreadCrumbs
                    title={"Complete Details"}
                    links={breadcrumbs}
                />
                <CompletionForm
                    data={data}
                    step={step}
                    selectedItem={selectedItem}
                    onSelectedItem={this.handleSelectedItem}
                    onStepChange={this.handleOnStepChange}
                    isLoading={isLoadingData}
                    history={history}
                />    
            </React.Fragment>
        );
    }
}

export default withSiteTemplate(withRouter(Form));