import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import NotFound from '../components/NotFound';
import Skeleton from 'react-loading-skeleton';
import Button from 'react-bootstrap/Button';
import { motion } from "framer-motion";
import Moment from 'moment';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Overlay from 'react-bootstrap/Overlay';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import { makePayment } from '../services/PaymentService';
import Modal from 'react-bootstrap/Modal';
import { Building, CreditCardFill, ArrowRight, FlagFill, TelephoneFill } from 'react-bootstrap-icons';
import { getItem } from './../services/StorageService';

export default class CompletionForm extends Component {

    constructor(props) {
        super(props);

        this.fairNumberRef = React.createRef();

        this.state = {
            showModal: false,
            showTooltip: true,
            formValidated: false,
            formSubmitting: false,
            formAmount: ""
        }
    }

    handleModalClose = () => {
        this.setState({ showModal: false });
    }

    handleOnStepChange = () => {
        const { onStepChange } = this.props;
        this.setState({ formValidated: false, formSubmitting: false, formAmount: "" }, () => onStepChange());
    }

    handleSubmit = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        var isCompleteForSubmission = form.checkValidity();

        this.setState({ formValidated: true, formSubmitting: isCompleteForSubmission });


        const { selectedItem } = this.props;

        const strForm = getItem('form');
        const formValues = JSON.parse(strForm);

        const { customerNo, customerName, customerNsInternalId } = formValues;

        if (isCompleteForSubmission) {

            var data = {
                customerNsInternalId: customerNsInternalId,
                customerNumber: customerNo,
                customerName: customerName,
                fairNumber: selectedItem.number,
                fairNsInternalId: selectedItem.nsInternalID,
                firstName: form["firstName"].value,
                lastName: form["lastName"].value,
                amount: form["amount"].value
            }

            try {
                const response = await makePayment(data);

                if (response.status === 200) {
                    window.location.href = response.data;
                    return;
                }

            } catch (e) {
                this.setState({ showModal: true, formValidated: true, formSubmitting: false });
            }
            
        }
    }

    onHandleAmountChange = e => {
        let formAmount = e.target.value;

        const regexp = /^[0-9\b.]+$/;

        // if value is not blank, then test the regex
        if (formAmount === '' || regexp.test(formAmount))
            this.setState({ formAmount })
    }

    goToContactUs = (e) => {
        return window.location.href = "https://scholastic.com.au/about-scholastic/contact-us/"
    }

    goBack = (e) => {
        e.preventDefault();
        const { history } = this.props;
        const previousUrl = `/search`;
        history.push(previousUrl);
    }

    handleShowTooltip = (value) => {
        this.setState({ showTooltip: value });
    }

    renderBadge = (text) => {
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">
                    This is the fair open date
                </Tooltip>}
            >
                <Badge pill size="lg" variant="success" className="list-pill">{Moment(text).format('DD/MM/YYYY')}</Badge>
            </OverlayTrigger>
        )
    }

    renderToolTip = (selectedItem) => {

        if (selectedItem === null)
            return null;

        const { data } = this.props;
        const { showTooltip } = this.state;

        const showToolTipExtraText = data.length > 1 ? "To choose a different fair please click here!" : "";
        return (
            <Overlay
                target={this.fairNumberRef.current}
                placement="bottom-end"
                rootClose={true}
                onHide={() => this.handleShowTooltip(false)}
                show={showTooltip}>
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <Tooltip id="button-tooltip" placement={placement} arrowProps={arrowProps} {...props} >
                        {`This is your fair number. ${showToolTipExtraText}`}
                    </Tooltip>
                )}
            </Overlay>
        )
    }

    renderLoading = () => {
        return (
            <React.Fragment>
                <Row className="page-headline">
                    <Col><Skeleton count={1} height={20} /></Col>
                </Row>
                <Row id="search-result">
                    <Col><Skeleton count={5} height={40} /></Col>
                </Row>
            </React.Fragment>
        )
    }

    renderEmptyResult = () => {
        return (
            <React.Fragment>
                <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.7 }} initial={{ opacity: 0 }} >
                    <Row>
                        <Col className="center">
                            <NotFound
                                title="There are currently no active Book Fairs"
                                description="Please refer to your Book Fair invitation, or contact your school, to confirm the dates of your upcoming Book Fair"
                                more={
                                    <React.Fragment>
                                        <Button variant="purple" size="md" style={{ marginRight: "1rem" }} onClick={this.goBack} >Go Back</Button>
                                        <Button variant="outline-info" size="md" onClick={(e) => this.goToContactUs(e)}><TelephoneFill />  Contact Us</Button>
                                    </React.Fragment>}
                            />
                        </Col>
                    </Row>
                </motion.div>
            </React.Fragment>
        )
    }

    renderForm = () => {

        const { formValidated, formAmount, formSubmitting } = this.state;

        return (
            <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.7 }} initial={{ opacity: 0 }} >
                <Form noValidate validated={formValidated} onSubmit={this.handleSubmit}>
                    <p id="completion-form-instruction">Complete the form below and use our secure payment method to complete the transaction:</p>
                    <div id="search-result">
                        <Form.Group controlId="validationFirstName">
                            <Form.Control name="firstName" size="md" required type="text" maxLength="100" placeholder="First Name" />
                            <Form.Control.Feedback type="invalid">
                                Please provide a first name
                    </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationLastName">
                            <Form.Control name="lastName" size="md" required type="text" maxLength="100" placeholder="Last Name" />
                            <Form.Control.Feedback type="invalid">
                                Please provide a last name
                    </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationAmount">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl name="amount" required value={formAmount} placeholder="Amount" maxLength="10" onChange={this.onHandleAmountChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a payment amount of greater than $0.00
                            </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Alert variant={"warning"}>
                                Please note you will be redirected to our payment facilitator page to make a payment. Once you have paid you will then be redirected back to us with a receipt number.
                        </Alert>
                            <Button disabled={formSubmitting} variant="purple" type="submit" className="mb-2">
                                {formSubmitting ? <Spinner id="btn-make-payment-icon" animation="border" variant="light" size="sm" /> : <CreditCardFill id="btn-make-payment-icon" />}
                                Make Payment
                    </Button>
                        </Form.Group>
                    </div>
                </Form>
            </motion.div>
        )
    }

    renderFairSelection = (data) => {
        const { onSelectedItem } = this.props;

        var results = data.map(fair => <ListGroup.Item key={fair.number} onClick={() => onSelectedItem(fair)} action><FlagFill className="primary-color list-leading-indicator indicator-trailing-space" />{`${fair.number}`} {this.renderBadge(fair.start.date)}<span className="float-right"><ArrowRight className="primary-color" /></span></ListGroup.Item>)

        return (
            <React.Fragment>
                <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.7 }} initial={{ opacity: 0 }} >
                    <p id="completion-form-instruction">Please select the fair you would like to pay for:</p>
                    <ListGroup id="search-result" className="shadow">
                        {results}
                    </ListGroup>
                </motion.div>
            </React.Fragment>
        )
    }

    render() {
        const { isLoading, selectedItem, step, data } = this.props;
        const { showModal } = this.state;

        var view = null;

        if (isLoading) {
            view = this.renderLoading();
        } else {
            switch (step) {
                case 1:
                    view = this.renderFairSelection(data);
                    break;
                case 2:
                    view = selectedItem === null ? this.renderFairSelection(data) : this.renderForm();
                    break;
                default:
                    view = this.renderEmptyResult();
                    break;
            }
        }

        const form = getItem('form');
        var formValues = form === undefined ? null : JSON.parse(form);

        return (
            <Container id="completion-form-container">
                <Row id="completion-form-header-container">
                    <Col sm={12} md={10} lg={10}>
                        <h2 id="completion-form-header-title"><Building /> { formValues !== null ? formValues.customerName : "" }</h2>
                    </Col>
                    <Col id="completion-form-details-container" sm={12} md={2} lg={2}>
                        <p className="zero-margin">{selectedItem === null ? "" : Moment(selectedItem.start.date).format('DD/MM/YYYY')}</p>
                        <p ref={this.fairNumberRef} className="zero-margin" onMouseOver={() => this.handleShowTooltip(true)} onMouseOut={() => this.handleShowTooltip(false)} id="completion-form-header-details" onClick={() => this.handleOnStepChange()}>{selectedItem === null ? "" : selectedItem.number}</p>
                        {this.renderToolTip(selectedItem)}
                    </Col>
                </Row>
                {view}
                <Modal show={showModal} onHide={this.handleModalClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>We could not generate the payment form at this time. Please try again, or if you continue to have issues please contact us.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleModalClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}