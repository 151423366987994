import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from './pages/Home';
import Search from './pages/Search';
import Form from './pages/Form';
import Receipt from './pages/Receipt';
import Error from './pages/Error';

export default class App extends Component {
  displayName = App.name

  render() {
      return (
          <Router>
              <Switch>
                  <Route path="/errorpayment" component={() => Error} />
                  <Route path="/receipt" component={() => Receipt} />
                  <Route path="/form" component={() => Form} />
                  <Route path="/search" component={() => Search} />
                  <Route path="/" component={() => Home} />
              </Switch>
          </Router>
    );
  }
}
