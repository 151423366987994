import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BookImg from '../assets/images/book.png';

export default class Footer extends Component {
    render() {
        return (
            <div id="footer">
                <Container id="footer-content">
                    <Row>
                        <Col xs={6} md={3} lg={3}>
                            
                            <h5 className="secondary-color"><img src={BookImg} alt="Scholastic Australia" /> Scholastic</h5>
                            <ul className="footer-list">
                                <li><a href="https://scholastic.com.au/">Scholastic Australia</a></li>
                                <li><a href="https://emea.scholastic.com/en/global-websites">Scholastic Worldwide</a></li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} lg={3}>
                            <h5 className="secondary-color">Book Fairs</h5>
                            <ul className="footer-list">
                                <li><a href="https://scholastic.com.au/book-fairs/why-host-a-book-fair/">Hosting a fair</a></li>
                                <li><a href="https://scholastic.com.au/book-fairs/discover-the-books/">Discover the books</a></li>
                                <li><a href="https://scholastic.com.au/book-fairs/themes/">Themes</a></li>
                                <li><a href="https://scholastic.com.au/book-fairs/support/">Support</a></li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} lg={3}>
                            <h5 className="secondary-color">Shop</h5>
                            <ul className="footer-list">
                                <li><a href="https://shop.scholastic.com.au/">The Store</a></li>
                                <li><a href="https://www.schoolessentials.com.au/">School Essentials</a></li>
                                <li><a href="https://bookclubs.scholastic.com.au/">Club Teachers</a></li>
                                <li><a href="https://mybookclubs.scholastic.com.au/Parent/Login.aspx">Club Parents</a></li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} lg={3}>
                            <h5 className="secondary-color">Corporate</h5>
                            <ul className="footer-list">
                                <li><a href="https://www.scholastic.com.au/about-scholastic/scholastic-blog-and-news/">News</a></li>
                                <li><a href="https://www.scholastic.com.au/about-scholastic/terms-of-use/">Terms of Use</a></li>
                                <li><a href="https://www.scholastic.com.au/about-scholastic/privacy-policy/">Privacy Policy</a></li>
                                <li><a href="https://www.scholastic.com.au/about-scholastic/contact-us/">Contact Us</a></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row id="copyright">
                        <Col>
                            Copyright &copy; 2020 Scholastic Australia. All Rights Reserved.
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}