import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import { CircleFill } from 'react-bootstrap-icons';

export default class Notes extends Component {
    render() {
        return (
            <Container id="notes">
                <Row>
                    <Col><h4>Important Notes</h4></Col>
                </Row>
                <Row>
                    <Col>
                        <Table id="notes-table" borderless striped hover className="shadow">
                            <tbody>
                                <tr>
                                    <td><CircleFill size={10} className="primary-color list-leading-indicator" /></td>
                                    <td>Your school will only be available for selection if they are hosting a Book Fair</td>
                                </tr>
                                <tr>
                                    <td><CircleFill size={10} className="primary-color list-leading-indicator" /></td>
                                    <td>Please refer to your Book Fair invitation, or contact your school, to confirm the dates of your upcoming Book Fair</td>
                                </tr>
                                <tr>
                                    <td><CircleFill size={10} className="primary-color list-leading-indicator" /></td>
                                    <td>Please note that making a payment does not order your child's books</td>
                                </tr>
                                <tr>
                                    <td><CircleFill size={10} className="primary-color list-leading-indicator" /></td>
                                    <td>After your payment has been accepted, you will be given an 8-digit receipt number</td>
                                </tr>
                                <tr>
                                    <td><CircleFill size={10} className="primary-color list-leading-indicator" /></td>
                                    <td>Please record your receipt number in the space provided on your Book Fair form, located on the back page of your invitation</td>
                                </tr>
                                <tr>
                                    <td><CircleFill size={10} className="primary-color list-leading-indicator" /></td>
                                    <td>Remember to also record the amount paid and provide the details of the credit card holder on this form. Please give your completed Receipt Record to your child. He or she may bring this to the Book Fair and use it as payment for their chosen books</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 id="notes-footer" className="primary-color">Thank you for supporting Scholastic Book Fairs!</h5>
                    </Col>
                </Row>
            </Container>
        );
    }
}