import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default class Results extends Component {

    renderLinks = (links) => {

        if (links === null || links === undefined)
            return null;

        return links.map(l => <Breadcrumb.Item key={l.name} href={l.link}>{l.name}</Breadcrumb.Item>)
    }

    render() {
        const { title, links } = this.props;
        return (
            <div id="breadcrumb-banner">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Breadcrumb>
                                {this.renderLinks(links)}
                            </Breadcrumb>
                            <h1>{title}</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
