import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import SearchBar from './SearchBar';
import fairLogo from './../assets/images/fairlogo.png';

export default class Hero extends Component {
    render() {
        const { searchTerm, searchState, searchBy, onSearch } = this.props;
        
        return (
            <Jumbotron id='hero'>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <img src={fairLogo} alt="Scholastic Book Fairs Payments" />
                            <h1 className="calvert primary-color">Book Fairs Parent Payments</h1>
                            <h5 className="secondary-color">Use your credit card to prepay for your child's Book Fair choices.</h5>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                        </Col>
                    </Row>
                    <Row id="hero-search">
                        <Col xs={12} md={12} lg={12}>
                            <SearchBar
                                term={searchTerm}
                                state={searchState}
                                onSearch={onSearch}
                                searchBy={searchBy} />
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }
}
