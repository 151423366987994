import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import CorpLogo from '../assets/images/corplogo.png';

export default class Navigation extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Navbar expand="lg">
                            <Navbar.Brand href="https://scholastic.com.au"><img src={CorpLogo} alt="Scholastic" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse className="justify-content-end">
                                <Nav>
                                    <Nav.Link href="https://www.scholastic.com.au/book-fairs/">Book Fairs</Nav.Link>
                                    <Nav.Link href="https://scholastic.com.au/about-scholastic/privacy-policy/">Privacy</Nav.Link>
                                    <Nav.Link href="https://www.scholastic.com.au/about-scholastic/contact-us/">Contact Us</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        );
    }
}
