import React, { Component } from 'react';
import { withRouter } from "react-router";
import Hero from '../components/Hero';
import Notes from '../components/Notes';
import withSiteTemplate from '../HOC/site';
import { storeItem, removeItem, getItem } from './../services/StorageService';
import ReactGA from 'react-ga';

class Home extends Component {
    
    componentDidMount() {
        if (typeof (Storage) === "undefined") {
            console.log("Local storage is not supported on this browser. Please use an up to date browser.");
            // The condition isn't met, meaning local storage isn't supported
        }

        ReactGA.initialize('UA-000000-01');
        ReactGA.pageview(window.location.pathname + window.location.search);

        // set default values
        const searchTerm = '';

        storeItem('search', JSON.stringify({ searchTerm }));
        removeItem('form');
    }
    
    handleOnSearch = (e, searchTerm) => {
        const { history } = this.props;

        const search = getItem('search');
        const query = JSON.parse(search);

        storeItem('search', JSON.stringify(query));

        history.push('/search');
    }

    render() {
        return (
            <React.Fragment>
                <Hero onSearch={this.handleOnSearch} searchBy={'school'} />
                <Notes />
            </React.Fragment>
        );
    }
}

export default withSiteTemplate(withRouter(Home));